@font-face {
	font-family: 'SUIT';
	font-weight: 100;
	src: url('./SUIT-Thin.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 200;
	src: url('./SUIT-ExtraLight.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 300;
	src: url('./SUIT-Light.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 400;
	src: url('./SUIT-Regular.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 500;
	src: url('./SUIT-Medium.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 600;
	src: url('./SUIT-SemiBold.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 600;
	src: url('./SUIT-Bold.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 800;
	src: url('./SUIT-ExtraBold.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 900;
	src: url('./SUIT-Heavy.woff2') format('woff2');
}
